<template>
  <div class="arttextstyle">
    <div class="arttextstyle__wrapper">
      <div
        class="arttextstyle__background"
        :style="{
          backgroundImage: `url(${featuredPost.header_image})`,
        }"
      ></div>
      <div class="arttextstyle__main-container">
        <div class="arttextstyle__details-box">
          <h2 class="arttextstyle__details-box_title">
            {{ featuredPost.title }}
          </h2>
          <p class="arttextstyle__details-box_data">
            <span>{{
              featuredPost.date_published | _moment("MMM DD YYYY", "YYYY-MM-DD, hh:mm a")
            }}</span>
            <span class="arttextstyle__details-box_data--devider"> | </span>
            <span> by arttextstyle </span>

            <span class="arttextstyle__details-box_data--devider"> | </span>
            <span>{{ featuredPost.read_time || "5min" }} read</span>
          </p>

          <div v-html="featuredPost.details" class="arttextstyle__details-box_details"></div>
          <BaseButton
            class="button button--xs button--white"
            text=" READ ARTICLE"
            @click="$router.push({ path: `/media/arttextstyle/${featuredPost.slug}` })"
          />
        </div>

        <div class="arttextstyle__title-box" ref="titleBox">
          <h2 class="arttextstyle__title-box_title">arttextstyle</h2>
          <div class="arttextstyle__title-box_right">
            <div class="arttextstyle__title-box_right-content">
              <Tag
                class="tag--public"
                :class="{ 'tag--selected': item.selected }"
                v-for="(item, index) in getVisibleTags"
                :key="`tag-${index}`"
                :value="item.title"
                @click="changeFilterValue(item)"
              />
              <AdditionalInfo
                v-if="getHiddenTags.length"
                class="additional-tags--public"
                :items="getHiddenTags"
                :parentClientRect="tagsClientRect"
                :isPublic="true"
                :isSearch="true"
                @getParentClientRect="getParentClientRect"
                @changeFilterValue="changeFilterValue"
              />
            </div>
            <div class="divider divider--vertical"></div>
            <div class="arttextstyle__title-box_right-content">
              <PublicSelect :items="sortData" :selected="cardData.sort" @input="selectSort" />
            </div>
          </div>
        </div>

        <div class="arttextstyle__arttextstyle-box" v-if="arttextstyles.length">
          <router-link
            v-for="(item, key) in arttextstyles"
            :key="`${key}-item`"
            :to="{ path: `/media/arttextstyle/${item.slug}` }"
            v-slot="{ navigate }"
            custom
          >
            <div class="arttextstyle__arttextstyle-box_card" @click="navigate">
              <!-- <div
                class="image"
                :style="{
                  backgroundImage: `url(${item.search_image}`,
                }"
                @click="$router.push({ path: `/media/arttextstyle/${item.slug}` })"
              >
                <div class="image__preview"><p>Click to View</p></div>
              </div> -->
              <div class="image-box">
                <img class="image" :src="item.search_image" :alt="item.title" />
                <div class="hover-container">Click to View</div>
              </div>
              <span class="tag">arttextstyle</span>
              <h3 class="title" @click="$router.push({ path: `/arttextstyle/${item.slug}` })">
                {{ item.title }}
              </h3>
              <span class="data">{{ item.date_published }} </span>
            </div>
          </router-link>
        </div>
        <h6 class="arttextstyle__empty" v-else>No Arttextstyle</h6>
        <div class="arttextstyle__button-box" v-if="hasMorePages">
          <BaseButton
            class="button button--xs button--white"
            text="LOAD MORE"
            @click.native="loadMore"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import Tag from "@/components/Tag.vue";
import AdditionalInfo from "@/components/AdditionalInfo.vue";

export default {
  components: { BaseButton, PublicSelect, Tag, AdditionalInfo },

  data() {
    return {
      tagsClientRect: { bottom: 0, right: 0 },
      sortData: [
        { id: "newest_to_oldest", title: "Newest to Oldest" },
        { id: "oldest_to_newest", title: "Oldest to Newest" },
        { id: "title_a_z", title: "Title (A-Z)" },
        { id: "title_z_a", title: "Title (Z-A)" },
        { id: "author_a_z", title: "Author (A-Z)" },
        { id: "author_z_a", title: "Author (Z-A)" },
      ],
      cardData: {
        sort: "newest_to_oldest",
        tags: "",
        page: 1,
      },
    };
  },
  head: {
    title: function () {
      return {
        inner: "Arttextstyle browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Arttextstyle browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Arttextstyle browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Arttextstyle browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Arttextstyle browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Arttextstyle browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Arttextstyle browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Arttextstyle browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Arttextstyle browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    await this.apiGetArttextstyles(this.cardData);
    await this.apiGetFiltersBlock();

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: true,
      },
      {
        title: "Arttextstyle",
        to: "/media/arttextstyle",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },
  watch: {
    tagsData: {
      handler: function (val, oldVal) {
        let type = "";
        val.forEach(el => {
          if (el.selected) {
            type = el.id === "" ? "" : el.id;
          }
        });

        this.cardData.type = type;
        this.selectTypes(type);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("publicapi/blogs", {
      arttextstyles: "getArttextstyles",
      hasMorePages: "getHasMorePages",
      featuredPost: "getFeaturedPost",
      tagsData: "getFilters",
    }),

    getVisibleTags() {
      return this.tagsData.slice(0, 5);
    },

    getHiddenTags() {
      return this.tagsData.slice(5, this.tagsData.length);
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapMutations("publicapi/blogs", ["changeFilterValue"]),
    ...mapActions("publicapi/blogs", ["apiGetArttextstyles", "apiGetFiltersBlock"]),

    getParentClientRect() {
      this.tagsClientRect = this.$refs.titleBox.getBoundingClientRect();
    },

    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.cardData, page: this.cardData.page + 1 };
        let success = await this.apiGetArttextstyles(data);
        if (success) {
          this.cardData = data;
        }
      }
    },
    async selectTypes(types) {
      let data = { ...this.cardData, page: 1, tags: types };
      let success = await this.apiGetArttextstyles(data);
      if (success) {
        this.cardData = data;
      }
    },
    async selectSort({ id }) {
      let data = { ...this.cardData, sort: id, page: 1 };
      let success = await this.apiGetArttextstyles(data);
      if (success) {
        this.cardData = { ...data };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.arttextstyle {
  display: flex;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58.4rem;
    z-index: 0;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $xs) {
      height: 33.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.45);
      z-index: 1;
    }
    &::after {
      content: "";
      bottom: -1px;
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        0deg,
        rgba($color: $lightBlack, $alpha: 1) 0%,
        rgba($color: $lightBlack, $alpha: 0) 100%
      );
      z-index: 1;
    }
  }

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40rem;
    padding: 0rem 10.5rem 23.5rem;
    z-index: 2;
    @media screen and (max-width: $md) {
      margin-top: 24.5rem;
      padding: 0rem 8rem 7.4rem;
    }
    @media screen and (max-width: $sm) {
      padding: 0rem 3rem 7.4rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0rem 25px 24rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 72%;
    @media screen and (max-width: $md) {
      width: 100%;
    }
    .button {
      margin-bottom: 15px;
    }
    &_title {
      font-size: 5.2rem;
      line-height: 1.25;
      @media only screen and (max-width: $md) {
        font-size: 35px;
      }
    }
    &_data {
      display: flex;
      font-size: 14px;
      line-height: 1.8;
      text-transform: uppercase;
      margin-bottom: 25px;
      &--devider {
        text-align: center;
        width: 25px;
        display: block;
      }
      @media screen and (max-width: $xs) {
        font-size: 14px;
      }
    }
    &_details {
      font-size: 2.35rem;
      line-height: 1.55;
      margin-bottom: 35px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 9rem 0rem 6rem;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: $xs) {
      margin: 8.5rem 0rem 2rem;
    }
    &_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      @media screen and (max-width: $md) {
        width: 100%;
        flex-direction: column;
        .divider {
          display: none;
        }
        &-content {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
      &-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;

        // & > * {
        //   margin-bottom: 10px;
        // }
      }
      @media screen and (max-width: $lg) {
        margin-top: 30px;
        margin-left: -10px;
      }
      @media screen and (max-width: $xs) {
        &-content {
          flex-wrap: wrap;
        }
      }
    }
    &_title {
      font-size: 5.2rem;
      line-height: 1.25;
      @media screen and (max-width: $md) {
        font-size: 35px;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 50px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  &__arttextstyle-box {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3.9rem;
    grid-row-gap: 3.9rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 25px;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
          border-radius: 4px;
        }
      }

      // .image {
      //   position: relative;
      //   background-size: cover;
      //   background-position: center;
      //   width: 100%;
      //   height: 27vw;
      //   margin-bottom: 25px;
      //   border-radius: 4px;
      //   @media screen and (max-width: $md) {
      //     height: 40vw;
      //   }
      //   @media screen and (max-width: $xs) {
      //     height: 90vw;
      //   }
      //   &__preview {
      //     position: absolute;
      //     height: 100%;
      //     width: 100%;
      //     display: flex;
      //     align-items: center;
      //     justify-self: center;
      //     opacity: 0;
      //     border-radius: 4px;
      //     transition: all 0.3s ease;
      //     p {
      //       width: 100%;
      //       text-align: center;
      //       font-size: 3rem;
      //       line-height: 1.67;
      //       letter-spacing: -1px;
      //     }
      //   }
      //   &:hover {
      //     .image__preview {
      //       opacity: 1;
      //       background-color: rgba($color: $blue, $alpha: 0.83);
      //     }
      //   }
      // }
      .tag {
        display: flex;
        justify-content: center;
        align-content: center;
        width: max-content;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 5px;
        font-size: 14px;
        line-height: 1.8;
        padding: 0.1rem 1rem;
        margin-bottom: 10px;
      }
      .title {
        font-size: 3.125rem;
        line-height: 1.3;
        margin-bottom: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        @media screen and (max-width: $xxl) {
          font-size: 24px;
        }
        @media screen and (max-width: $xs) {
          font-size: 22px;
        }
        // &:hover {
        //   text-decoration: underline;
        // }
      }
      .data {
        font-size: 14px;
        line-height: 1.8;
        text-transform: uppercase;
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;

    .button {
      min-width: 180px;
    }
  }
}
</style>
