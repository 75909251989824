var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideSelect),expression:"hideSelect"}],staticClass:"form-control"},[_c('div',{staticClass:"form-control__select",class:{
      'form-control__select--disabled': _vm.disabled || _vm.visible,
    },on:{"click":function($event){_vm.visible = !_vm.visible}}},[_vm._v(" "+_vm._s(_vm.defaultLabel)+" "+_vm._s(_vm.element)+" ")]),_c('div',{staticClass:"form-control__items",class:{ 'form-control__items--visible': !_vm.disabled ? _vm.visible : false }},[_c('div',{staticClass:"form-control__items_body"},[_c('ul',{staticClass:"list"},_vm._l((_vm.items),function(i){return _c('li',{key:i.id},[_c('a',{staticClass:"list__item",class:{
              'list__item--active': _vm.element === i.title,
            },on:{"click":function($event){$event.preventDefault();_vm.$emit('input', {
                id: i.id,
                title: i.title,
              });
              _vm.visible = false;}}},[_vm._v(_vm._s(i.title))])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }